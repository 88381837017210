/* eslint-disable no-undef */
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import { Loader } from 'google-maps';
import { get } from 'lodash';

$(document).ready(async () => {
  document.body.addEventListener("yotpoLoyaltyDiscountApplied", function(e) {
    calculateTotal(true)
  });
  const googleKey = 'AIzaSyByA9IDMQRZyQ_IBrHOHl19cjpoJhDNWTY';
  const googleLoader = new Loader(googleKey, { libraries: ['places'] });
  const google = await googleLoader.load();

  let placeSearch;
  let autocomplete;
  let address;
  let enable_reward = true;
  let address_edit;
  let which_aeropay = '';
  let aeropay_fee_amount = 0;
  const componentForm = {
    street_number: 'short_name',
    route: 'long_name',
    locality: 'long_name',
    administrative_area_level_1: 'short_name',
    country: 'long_name',
    postal_code: 'short_name'
  };
  if ($('#store_location').val() != 0) {
    $('#search').removeAttr('disabled');
    $('#promo_product_search').removeAttr('disabled');
    $('#promo_product_search').prop('placeholder', '');
    $('#search').prop('placeholder', '');

    $('#brand_search').removeAttr('disabled');
    $('#brand_search').prop('placeholder', '');
    $('#submit_button').prop('disabled', 'disabled');
    if ($('#store_location').val()){
      $.get('/marketplace_by_location', {
        location_id: $('#store_location').val()
      }).done(data => {
        $('#store_marketplace').html('');
        if(data.marketplaces.length){
          $('#storeMarketplaceId').css('display', 'block')
        }else{
          $('#storeMarketplaceId').css('display', 'none');
        }
        $('#store_marketplace').append(
          '<option disabled="disabled" selected="selected">Select Marketplace</option>'
        );
        $.each(data.marketplaces, function(index, val) {
          $('#store_marketplace').append(`<option value="${  val.id  }">${  val.name  }</option>`);
        });
      });
    }
  }


  autocompleteMapBox();

  // $('.new_costomer_for_order').on('click', function() {
  autocompleteMapBox('customer');
  // });

  // $('#edit_customer_button').on('click', function() {
  autocompleteMapBox('customer', 'edit_customer');
  // });
  // autocompleteCustomerMapBox();
  // address = new google.maps.places.Autocomplete(
  //   document.getElementById('address')
  // );

  // address_edit = new google.maps.places.Autocomplete(
  //   document.getElementById('address_edit')
  // );

  // address.addListener('place_changed', function() {
  //   fillInAddress('address');
  // });

  // autocomplete.addListener('place_changed', function() {
  // fillInAddress('delivery_address');
  // });

  // address_edit.addListener('place_changed', function() {
  //   fillInAddress('address_edit');
  // });
  function phoneMask(first_number, second_number, el) {
    if (first_number == '1' || second_number == '1') {
      var phones = [{ mask: '#(###) ###-####' }];
    } else {
      var phones = [{ mask: '(###) ###-####' }];
    }

    $(`#${  el}`).inputmask({
      mask: phones,
      greedy: false,
      definitions: {
        '#': { validator: '[0-9]', cardinality: 1 },
        '*': { validator: '1?' }
      }
    });
  }

  $('#cell_phone').keyup(function() {
    const phone_types = $('#cell_phone').val();
    const first_number = phone_types.charAt(0);
    const second_number = phone_types.charAt(1);
    phoneMask(first_number, second_number, 'cell_phone');
  });

  $('#cell_phone_edit').keyup(function() {
    const phone_types = $('#cell_phone_edit').val();
    const first_number = phone_types.charAt(0);
    const second_number = phone_types.charAt(1);
    phoneMask(first_number, second_number, 'cell_phone_edit');
  });

  $('#zip_code').keyup(function() {
    let zip_code_value = [{ mask: '#####' }];
    $('#zip_code').inputmask({
      mask: zip_code_value,
      placeholder: '',
      definitions: {
        '#': { validator: '[0-9]', cardinality: 1 }
      }
    });
    calculateTotal();
  });

  function checkRequiredFields() {
    if (
      $('#store_location').val() !== null &&
      $('#store_location').val() != 0 &&
      $('#customer_search')
        .val()
        .trim() &&
      ($('#new_order')
        .find('input.mapboxgl-ctrl-geocoder--input')
        .val() ||
        $('#in_store_pickup').is(':checked')) &&
      $('#zip_code').val() &&
      ($('#order_origin').val() != 0 || $('#in_store_pickup').is(':checked')) &&
      $('#table_body').children().length !== 0
    ) {
      $('#submit_button').prop('disabled', false);
    } else {
      $('#submit_button').prop('disabled', 'disabled');
    }
  }
  function addTaxesToOrderItems(order_items, taxes) {
    $.each(order_items, function(index, val) {
      taxes.filter(function (el) {
        if (el.productIdentifier == val.id){
          order_items[index]['cost_info'].sales_tax = el.salesTax;
          order_items[index]['cost_info'].excise_tax = el.exciseTax;
          order_items[index]['cost_info'].city_tax = el.cityTax;
        }
      });
    });
  }
  function updateCost(order_items, new_order_items, is_cost_info = false) {
    $.each(order_items, function(index, val) {
      new_order_items.filter(function (el) {
        if (el.id == val.id) {
          if (is_cost_info){
            order_items[index].cost = el.cost;
          }else{
            order_items[index]['cost_info'].cost = el.cost;
            $('#cost_' + index).html('$' + el.cost)
          }
        }
      });
    });
  }
  function removeCustomer() {
    var location_store = $('#store_location').find(':selected').data('store-id')
    let app_user_store = $('#order_app_user_store_id').val()
    let app_user_marketplace = $('#order_app_user_marketplace_id').val()
    let marketplace_ids = []
      $('#store_marketplace option').each(function() {
      marketplace_ids.push($(this).val())
    })

    console.log(location_store, app_user_store, marketplace_ids)
    if (app_user_store && (parseInt(location_store) !=  parseInt(app_user_store))){
      $('#customerInfoDiv').hide();
      $('#order_app_user_id').val('');
      $('#customer_search').val('');
    }else if (app_user_marketplace && !marketplace_ids.includes(app_user_marketplace)){
      $('#customerInfoDiv').hide();
      $('#order_app_user_id').val('');
      $('#customer_search').val('');
    }

  }
  function intervals(startString, endString) {
    let current = new Date()
    let start = current.toLocaleDateString() + ' ' + startString
    let end = current.toLocaleDateString() + ' ' + endString
    start = moment(start);
    end = moment(end);
    start.minutes(Math.ceil(start.minutes() / 15) * 15);
    let result = [];
    while (start < end) {
      result.push(start.format('HH:mm'));
      start.add(15, 'minutes');
    }
    return result;
  }

  function removeProduct(){
    $('#submit_button').prop('disabled', 'disabled');
   $('#table_body').html('')
    order_items = {}
      $('#remove_delivery_fee').show();
      $('#subtotal_label').text('$0.00');
      $('#delivery_cost_label').text('$0.00');
      $('#total_label').text('$0.00');
      // $("#cc_fee_label").text(currencyFormatter.format(cc_fee));
      $('#discount_cost_label').text('$0.00');
      $('#reward_discount_cost_label').text('$0.00');
      $('#additional_label').text('$0.00');
      $('#recreational_fee_label').text('$0.00');
      $('#excise_tax_label').text('$0.00');
      $('#sales_tax_label').text('$0.00');
      $('#city_tax_id').text('$0.00');
      $('#debit_fee_label').text('$0.00');
      $('#aeropay_fee_label').text('$0.00');
      $('#paytender_fee_label').text('$0.00');
  }

  function shippingFields() {
    let location_is_shipping = $('#store_location')
      .find(':selected')
      .data('is-shipping');
    console.log('location_is_shipping', location_is_shipping);
    if (location_is_shipping) {
      $('#delivery_address_label').html('Street Address');
      $('#comments-label').html('Shipping Request');
      $('#apartment_label').html('Apartment, Suite, Unit, Blinding, Etc');
      $('#fullName').css('display', 'block');
      $('#phoneNumber').css('display', 'block');
      $('#costShipping').css('display', 'block');
      $('#requestDeliveryTimeId').css('display', 'none');
      $('#storePickupId').css('display', 'none');
      $('.cash-curbside').hide();
      $('#cash').attr('checked', false);
       delivery_cost = 0
    } else {
      $('#delivery_address_label').html('Delivery address');
      $('#apartment_label').html('Apartment number');
      $('#comments-label').html('Comments');
      $('#fullName').css('display', 'none');
      $('#phoneNumber').css('display', 'none');
      $('#costShipping').css('display', 'none');
      $('#requestDeliveryTimeId').css('display', 'block');
      $('#storePickupId').css('display', 'block');
      $('.cash-curbside').show();
    }
  }
  function removePromo() {
    $('#clear_discount_button').click();
  }
  function removeBrand() {
    $('#brand_search').val('');
    $('#brandId').val('');

  }
  function removeProducts() {
    order_items = {}
    $('#table_body').html('')
  }
  $('.mapboxgl-ctrl-geocoder--button').on('click', function() {
    calculateTotal();
  });

  $('#storeLocationId').on('change', function() {
    $.get('/marketplace_by_location', {
      location_id: $('#store_location').val()
    }).done(data => {
      $('#store_marketplace').html('');
      if(data.marketplaces.length){
        $('#storeMarketplaceId').css('display', 'block')
      }else{
        $('#storeMarketplaceId').css('display', 'none');
      }
      $('#store_marketplace').append(
        '<option disabled="disabled" selected="selected">Select Marketplace</option>'
      );
      $.each(data.marketplaces, function(index, val) {
        $('#store_marketplace').append(`<option value="${  val.id  }">${  val.name  }</option>`);
      });
      let times = intervals(data.start_time, data.end_time)
      $('#curbside_time').html('')
      $.each(times, function(index, value) {
        $('#curbside_time').append(`<option value="${  value  }">${  value  }</option>`)
      })
      removeCustomer()
      $.each(order_items, function(index, value) {
        $.get('/product_details', {
          product_id: value.id,
          location_id:  $('#store_location').val(),
          zip_code: $('#zip_code').val(),
          marketplace_id: $('#store_marketplace').val(),
          is_promo: false
        }).done(data => {
          updateItemUnitPrice(data);
        });
      });
    });
  });

    aeropay_fee_amount = gon.store.aeropay_fee;
    which_aeropay = 'store';

  $('#store_marketplace').on('change', function() {
    let marketplaceID = $('#store_marketplace').val();
    let storeId =  gon.store.id;
    $.get('/check_marketplace_aeropay', {marketplace_id: marketplaceID, store_id : storeId }).done(
      data => {
        if (
          (data.aeropay_enabled && data.has_aeropay) ||
          (data.aeropay_enabled &&
            !data.has_aeropay &&
            gon.store_aeropay_enabled)
        ) {
          if (data.aeropay_enabled && data.has_aeropay) {
            which_aeropay = 'marketplace';
            aeropay_fee_amount = data.marketplace_aeropay_fee;
          }
          $('#aeropay-option-div').css('display', 'inline-block');
          $('#aeropay-fee-div').css('display', 'block');
          $('.aeropay-option').css('display', 'inline-block');

        } else {
          $('#aeropay-option-div').css('display', 'none');
          $('#aeropay-fee-div').css('display', 'none');
          $('.aeropay-option').css('display', 'none');
        }
        enable_reward = data.enable_reward
        if(data.enable_reward){
          $('#rewards_block').show();
        }else{
          $('#rewards_block').hide();
        }
        let app_user_marketplace = $('#order_app_user_marketplace_id').val()
        let marketplace_ids = []
        $('#store_marketplace option').each(function() {
          marketplace_ids.push($(this).val())
        })
        console.log(marketplaceID, app_user_marketplace)
        if (app_user_marketplace && (marketplaceID != app_user_marketplace)){
          $('#customerInfoDiv').hide();
          $('#order_app_user_id').val('');
          $('#customer_search').val('');
        }
      }
    );
    $.each(order_items, function(index, value) {
      if (value) {
        $.get('/product_details', {
          product_id: value.id,
          location_id:  $('#store_location').val(),
          zip_code:  $('#zip_code').val(),
          marketplace_id: marketplaceID,
          is_promo: false
        }).done(data => {
          updateItemUnitPrice(data);
        });
      }
    });
  });

  $('#customer_search').on('change', function() {
    checkRequiredFields();
  });

  $('#new_order')
    .find('input.mapboxgl-ctrl-geocoder--input')
    .on('change', function() {
      calculateTotal();
    });

  $('#order_origin').on('change', function() {
    checkRequiredFields();
  });
  $('#cost_shipping').on('change', function() {
    delivery_cost = $('#cost_shipping').find(':selected').data('cost').toFixed(2);
    calculateTotal();
  });

  function fillInAddress(type) {
    const place =
      address.getPlace() || autocomplete.getPlace() || address_edit.getPlace();
    const addressInfo = {};

    if (type == 'delivery_address') {
      $('#latId').val(place.geometry.location.lat());
      $('#lngId').val(place.geometry.location.lng());
    }
    if (place.address_components && place.address_components.length) {
      for (let i = 0; i < place.address_components.length; i++) {
        const addressType = place.address_components[i].types[0];
        if (addressType === 'street_number') {
          addressInfo.street_address =
            place.address_components[i][componentForm[addressType]];
        }
        // Street Name
        if (addressType === 'route') {
          addressInfo.street_address = `${addressInfo.street_address} ${
            place.address_components[i][componentForm[addressType]]
          }`;
        }
        // City
        if (addressType === 'locality') {
          addressInfo.city =
            place.address_components[i][componentForm[addressType]];
        }
        // State
        if (addressType === 'administrative_area_level_1') {
          addressInfo.state =
            place.address_components[i][componentForm[addressType]];
        }

        // Zip
        if (addressType === 'postal_code') {
          addressInfo.zip =
            place.address_components[i][componentForm[addressType]];
        }
      } // end for loop
      if (type == 'address') {
        document.getElementById(
          'customerLatId'
        ).value = place.geometry.location.lat();
        document.getElementById(
          'customerLngId'
        ).value = place.geometry.location.lng();
        document.getElementById('address').value = addressInfo.street_address;
        document.getElementById('city').value = addressInfo.city;
        document.getElementById('state').value = addressInfo.state;
        document.getElementById('zip').value = addressInfo.zip;
      } else if (type == 'address_edit') {
        document.getElementById('address_edit').value =
          addressInfo.street_address;
        document.getElementById('city_edit').value = addressInfo.city;
        document.getElementById('state_edit').value = addressInfo.state;
        document.getElementById('zip_edit').value = addressInfo.zip;
      } else {
        document.getElementById('zip_code').value = addressInfo.zip;
      }
    }
    calculateTotal();
  }
  let maxDate = moment().add(14, 'day');
  $('.datepicker').datetimepicker({
    format: 'YYYY-MM-DD HH:mm:ss',
    minDate: 0,
    maxDate: maxDate,
    defaultDate: null
  });

  $('#customerInfoDiv').hide();
  $('#customerInfoLoader').hide();
  // $('#search').attr('disabled', 'disabled');
  // $('#promo_product_search').attr('disabled', 'disabled');
  // $('#brand_search').attr('disabled', 'disabled');

  $('#cc_fee_button').hide();
  const is_cc = false;

  /*
  $("input[type=radio][name=payment_type]").change(function() {
    if (this.value === "Credit Card") {
      $("#cc_fee_button").show();
      is_cc = true;
    } else {
      $("#cc_fee_button").hide();
      is_cc = false;
      cc_fee = 0;
      calculateTotal();
    }
  }); */

  $('#store_location').change(() => {
    if ($('#store_location').val() == 0) {
      $('#search').attr('disabled', 'disabled');
      $('#promo_product_search').attr('disabled', 'disabled');
      $('#search').prop('placeholder', 'Select a Store Location First');

      $('#brand_search').attr('disabled', 'disabled');
      $('#brand_search').prop('placeholder', 'Select a Store Location First');
    } else {
      $('#search').removeAttr('disabled');
      $('#promo_product_search').removeAttr('disabled');
      $('#promo_product_search').prop('placeholder', '');
      $('#search').prop('placeholder', '');

      $('#brand_search').removeAttr('disabled');
      $('#brand_search').prop('placeholder', '');
      const pickupIsChecked = $('#in_store_pickup').is(':checked');
      if (pickupIsChecked == true) {
        $.get('/delivery_calc', {
          location_id: $('#store_location').val()
        }).done(data => {
          $('#zip_code').val(data.zip_code);
          $('#city_name').val(data.city_name);
        });
      }
      $.each(order_items, function(index, value) {
        if (value) {
          $.get('/is_in_stock', {
            product_id: value.id,
            change_location: true,
            promo: false,
            location_id: $('#store_location').val()
          }).done(avaliData => {
            console.log('avaliData', avaliData)
            if (avaliData.available === false) {
              alert(
                `${value.name} is out of stock at the ${$(
                  '#store_location option:selected'
                ).text()} location.`
              );
            }
            removeProduct();
          });
        }
      });
      removeCustomer();
      shippingFields()
      removePromo();
      removeBrand();
      checkRequiredFields();
      calculateTotal();
    }
  });

  let customer_to_edit_id = 0;

  $('#date_of_birth').on('change', function() {
    const date = $('#date_of_birth')
      .val()
      .split('-');
    if (parseInt(date[0]) > new Date().getFullYear()) {
      $(this).val(`1901-${date[1]}-${date[2]}`);
    }
  });

  $('#date_of_birth_edit').on('change', function() {
    const date = $('#date_of_birth_edit')
      .val()
      .split('-');
    if (parseInt(date[0]) > new Date().getFullYear()) {
      $(this).val(`1901-${date[1]}-${date[2]}`);
    }
  });

  $('#edit_customer_button').click(() => {
    const selected_user = $('#order_app_user_id').val();
    if (selected_user.length > 0) {
      $.get('/get_customer_info', { customer_id: selected_user }).done(data => {
        $('#first_name_edit').val(data.first_name);
        $('#last_name_edit').val(data.last_name);
        $('#date_of_birth_edit').val(data.date_of_birth);
        $('#cell_phone_edit').val(data.cell_phone);
        $('#email_edit').val(data.email);
        if (data.selected_location) {
          $('#address_edit').val(data.selected_location.address);

          $('#edit_customer_modal')
            .find('input.mapboxgl-ctrl-geocoder--input')
            .val(data.selected_location.address);

          $('#apartment_number_edit').val(
            data.selected_location.apartment_number
          );
          $('#city_edit').val(data.selected_location.city);
          $('#state_edit').val(data.selected_location.state);
          $('#zip_edit').val(data.selected_location.zip);
          $('#lat_edit').val(data.selected_location.lat);
          $('#lng_edit').val(data.selected_location.lon);
        }

        customer_to_edit_id = data.id;

        $('#edit_customer_modal').modal();
      });
    } else {
      alert('Please select a customer first!');
    }
  });

  $('#view_customer_button').click(() => {
    const selected_user = $('#order_app_user_id').val();
    if (selected_user.length > 0) {
      const win = window.open(`/app_users/${selected_user}`, '_blank');
      if (win) {
        // Browser has allowed it to be opened
        win.focus();
      } else {
        // Browser has blocked it
        alert('Please allow popups for this website');
      }
    } else {
      alert('Please select a customer first!');
    }
  });

  $('#update_customer_button').click(() => {
    let error_count = 0;
    let error_message = '';
    const digits_cell_phone = $('#cell_phone_edit')
      .val()
      .replace(/\D/g, '');

    if (
      $('#first_name_edit').val() === '' ||
      $('#first_name_edit').val() === null
    ) {
      error_count++;
      error_message += '<li>First Name is required.</li>';
    }

    if (
      !$('#first_name_edit')
        .val()
        .match('^[a-zA-Z0-9*]+$') ||
      !$('#last_name_edit')
        .val()
        .match('^[a-zA-Z0-9*]+$')
    ) {
      error_count++;
      error_message +=
        '<li> First & Last Name can only be within English letters. </li>';
    }

    if (
      /\d/.test(
        $('#first_name_edit')
          .val()
          .charAt(0)
      ) ||
      $('#first_name_edit')
        .val()
        .charAt(0) == '*'
    ) {
      error_count++;
      error_message += '<li> First Name cannot start with a number or *. </li>';
    }

    if (
      $('#last_name_edit').val() === '' ||
      $('#last_name_edit').val() === null
    ) {
      error_count++;
      error_message += '<li> Last Name is required. </li>';
    }

    if (
      /\d/.test(
        $('#last_name_edit')
          .val()
          .charAt(0)
      ) ||
      $('#last_name_edit')
        .val()
        .charAt(0) == '*'
    ) {
      error_count++;
      error_message += '<li> Last Name cannot start with a number or *. </li>';
    }

    if (
      $('#date_of_birth_edit').val() !== '' ||
      $('#date_of_birth_edit').val() !== null
    ) {
      if (
        new Date().getFullYear() -
          parseInt(
            $('#date_of_birth_edit')
              .val()
              .split('-')[0]
          ) <
        18
      ) {
        error_count++;
        error_message += "<li> Date Of Birth can't be less than 18! </li>";
      }
      if (
        parseInt(
          $('#date_of_birth_edit')
            .val()
            .split('-')[0]
        ) < 1901
      ) {
        error_count++;
        error_message += '<li> Invalid Date Of Birth! </li>';
      }
    }

    if (
      $('#lng_edit').val() === '' ||
      ($('#lng_edit').val() === null && $('#lat_edit').val() === '') ||
      $('#lat_edit').val() === null
    ) {
      error_count++;
      error_message += '<li> Address Coordinates is required.</li>';
    }

    if (
      $('#cell_phone_edit').val() === '' ||
      $('#cell_phone_edit').val() === null
    ) {
      error_count++;
      error_message += '<li> Cell Phone is required. </li>';
    }

    if (digits_cell_phone !== '') {
      let has_error = false;
      if (digits_cell_phone.length > 9) {
        if (
          (digits_cell_phone.charAt(0) == '1' &&
            digits_cell_phone.charAt(1) == '1') ||
          (digits_cell_phone.length == 10 &&
            ['0', '1'].includes(digits_cell_phone.charAt(3))) ||
          (digits_cell_phone.length == 11 &&
            ['0', '1'].includes(digits_cell_phone.charAt(4)))
        ) {
          has_error = true;
        }
      } else {
        has_error = true;
      }
      if (has_error) {
        error_count++;
        error_message += '<li> Cell Phone is not a US number. </li>';
      }
    }

    if ($('#email_edit').val() === '' || $('#email_edit').val() === null) {
      error_count++;
      error_message += '<li> Email is required. </li>';
    }

    if ($('#address_edit').val() === '' || $('#address_edit').val() === null) {
      error_count++;
      error_message += '<li> Address is required. </li>';
    }

    if ($('#city_edit').val() === '' || $('#city_edit').val() === null) {
      error_count++;
      error_message += '<li>City is required.</li>';
    }

    if ($('#state_edit').val() === '' || $('#state_edit').val() === null) {
      error_count++;
      error_message += '<li> State must be two letters. </li>';
    }

    if (
      $('#zip_edit').val() === '' ||
      $('#zip_edit').val() === null ||
      $('#zip_edit').val().length < 5 ||
      $('#zip_edit').val().length > 5
    ) {
      error_count++;
      error_message += '<li> Zip must be 5 digits! </li>';
    }

    if (error_count > 0) {
      $('#errorMsg_edit').html(
        `There are ${error_count} error(s) in your submission.`
      );
      $('#errorsUl_edit').html(error_message);
      $('#errors_edit').show();
    } else {
      $('#errors_edit').hide();

      $.post('/update_customer_info', {
        customer_id: customer_to_edit_id,
        first_name: $('#first_name_edit').val(),
        last_name: $('#last_name_edit').val(),
        date_of_birth: $('#date_of_birth_edit').val(),
        cell_phone: $('#cell_phone_edit').val(),
        email: $('#email_edit').val(),
        address: $('#address_edit').val(),
        apartment_number: $('#apartment_number_edit').val(),
        city: $('#city_edit').val(),
        state: $('#state_edit').val(),
        zip: $('#zip_edit').val(),
        lat: $('#lat_edit').val(),
        lon: $('#lng_edit').val()
      }).done(data => {
        $('#customer_search').val(data.text);
        $('#edit_customer_modal').modal('hide');
        if (data.success) {
          toastr.success(
            '<div class="custInfoUpdate">The customer information has been updated</div>',
            '<div class="custUpdate">Customer Update!</div>'
          );
        } else {
          toastr.error('The customer information has not been update', 'Error');
        }
      });
    }
  });

  $('.mapboxgl-ctrl-geocoder--button').on('click', function() {
    $('#customerLngId').val('');
    $('#customerLatId').val('');
  });

  $('.mapboxgl-ctrl-geocoder--button').on('click', function() {
    $('#lng_edit').val('');
    $('#lat_edit').val('');
  });

  $('#customerAddId').on('click', function() {
    let error_count = 0;
    let error_message = '';
    const digits_cell_phone = $('#cell_phone')
      .val()
      .replace(/\D/g, '');

    if ($('#first_name').val() === '' || $('#first_name').val() === null) {
      error_count++;
      error_message += '<li> First Name is required. </li>';
    }

    if (
      /\d/.test(
        $('#first_name')
          .val()
          .charAt(0)
      ) ||
      $('#first_name')
        .val()
        .charAt(0) == '*'
    ) {
      error_count++;
      error_message += '<li> First Name cannot start with a number or *. </li>';
    }

    if (
      !$('#first_name')
        .val()
        .match('^[a-zA-Z0-9*]+$') ||
      !$('#last_name')
        .val()
        .match('^[a-zA-Z0-9*]+$')
    ) {
      error_count++;
      error_message +=
        '<li> First & Last Name can only be within English letters. </li>';
    }

    if ($('#last_name').val() === '' || $('#last_name').val() === null) {
      error_count++;
      error_message += '<li> Last Name is required. </li>';
    }

    if (
      /\d/.test(
        $('#last_name')
          .val()
          .charAt(0)
      ) ||
      $('#last_name')
        .val()
        .charAt(0) == '*'
    ) {
      error_count++;
      error_message += '<li> Last Name cannot start with a number or *. </li>';
    }

    if (
      $('#date_of_birth').val() !== '' ||
      $('#date_of_birth').val() !== null
    ) {
      if (
        new Date().getFullYear() -
          parseInt(
            $('#date_of_birth')
              .val()
              .split('-')[0]
          ) <
        18
      ) {
        error_count++;
        error_message += "<li> Date Of Birth can't be less than 18! </li>";
      }
      if (
        parseInt(
          $('#date_of_birth')
            .val()
            .split('-')[0]
        ) < 1901
      ) {
        error_count++;
        error_message += '<li> Invalid Date Of Birth! </li>';
      }
    }

    if ($('#cell_phone').val() === '' || $('#cell_phone').val() === null) {
      error_count++;
      error_message += '<li> Cell Phone is required. </li>';
    }

    if (digits_cell_phone !== '') {
      let has_error = false;
      if (digits_cell_phone.length > 9) {
        if (
          (digits_cell_phone.charAt(0) == '1' &&
            digits_cell_phone.charAt(1) == '1') ||
          (digits_cell_phone.length == 10 &&
            ['0', '1'].includes(digits_cell_phone.charAt(3))) ||
          (digits_cell_phone.length == 11 &&
            ['0', '1'].includes(digits_cell_phone.charAt(4)))
        ) {
          has_error = true;
        }
      } else {
        has_error = true;
      }
      if (has_error) {
        error_count++;
        error_message += '<li> Cell Phone is not a US number. </li>';
      }
    }

    if ($('#email').val() === '' || $('#email').val() === null) {
      error_count++;
      error_message += '<li> Email is required. </li>';
    }

    if ($('#address').val() === '' || $('#address').val() === null) {
      error_count++;
      error_message += '<li> Address is required. </li>';
    }

    if ($('#city').val() === '' || $('#city').val() === null) {
      error_count++;
      error_message += '<li> City is required. </li>';
    }

    if (
      $('#customerLngId').val() === '' ||
      $('#customerLngId').val() === null ||
      $('#customerLatId').val() === '' ||
      $('#customerLatId').val() === null
    ) {
      error_count++;
      error_message += '<li> Address Coordinates is required. </li>';
    }

    if (
      $('#state').val() === '' ||
      $('#state').val() === null ||
      $('#state').val().length < 2 ||
      $('#state').val().length > 2
    ) {
      error_count++;
      error_message += '<li> State must be two letters. </li>';
    }

    if (
      $('#zip').val() === '' ||
      $('#zip').val() === null ||
      $('#zip').val().length < 5 ||
      $('#zip').val().length > 5
    ) {
      error_count++;
      error_message += '<li> Zip must be 5 digits! </li>';
    }

    if (error_count > 0) {
      $('#errorsUl').html(error_message);
      $('#errors').show();

      return false;
    }
    $('#errors').hide();
    $('#customerAddId').prop('disabled', 'disabled');
    $.post('/create_customer', {
      email: $('#email').val(),
      first_name: $('#first_name').val(),
      last_name: $('#last_name').val(),
      date_of_birth: $('#date_of_birth').val(),
      cell_phone: $('#cell_phone').val(),
      store_id: $('#store').val(),
      location_id: $('#store_location').val(),
      address: $('#address').val(),
      apartment_number: $('#apartment_number').val(),
      city: $('#city').val(),
      state: $('#state').val(),
      zip: $('#zip').val(),
      lat: $('#customerLatId').val(),
      lon: $('#customerLngId').val()
    }).done(data => {
      if(data.success) {
        window.location.replace(data.redirect_to);
        return true;
      }
        $('#errorsUl').html(data.message);
        $('#errors').show();
        $('#customerAddId').prop("disabled", false);
        return false;

    });
  });

  $('#customer_address_button').click(() => {
    fillUserAddress();
  });

  fillUserAddress();

  function fillUserAddress() {
    if (!$('#order_app_user_id').val()) return;
    $.get('/get_customer_address', {
      customer_id: $('#order_app_user_id').val()
    }).done(data => {
      $('#order_delivery_address').val(data.address);
      $('#new_order')
        .find('input.mapboxgl-ctrl-geocoder--input')
        .val(data.address);
      loadCoords(data.address);
      $('#zip_code').val(data.zip);
      $('#city_name').val(data.city);
      $('#latId').val(data.lat);
      $('#lngId').val(data.lon);
      $('#apartment_number').val(data.apartment_number);
    });
  }

  $('#customer_search').autocomplete({
    selectFirst: true,
    minLength: 0,
    scroll: true,
    classes: {
      'ui-autocomplete': 'highlight-autocomplite'
    },
    source(request, response) {
      const location_id = $('#store_location').val();
      const marketplace_id = $('#store_marketplace').val();
      $.getJSON(
        '/customer_search',
        {
          term: request.term,
          loc_id: location_id,
          marketplace_id: marketplace_id
        },
        response
      );
    },
    select(e, ui) {
      $('#order_app_user_id').val(ui.item.value);
      $('#order_app_user_store_id').val(ui.item.store_id);
      $('#order_app_user_marketplace_id').val(ui.item.marketplace_id);
      customer_id = ui.item.value;
      this.value = ui.item.label;

      $('#orderDiv').attr('class', 'col-lg-9');
      $('#customerInfoLoader').show();
      loadCustomerInfo();

      return false;
    }
  });

  var customer_id = $('#order_app_user_id').val();
  let promo_code_id = $('#promoId').val();
  let subTotal = 0.0;
  let excludedSubTotal = 0.0;
  let totalCost = 0.0;
  let exciseTax = 0;
  let salesTax = 0;
  let city_tax_amount = 0;
  let business_city_tax = 0;
  let cityTaxId = 0;
  let has_tax = false;
  let recreationalFee = 0;
  let order_items = {};
  const product_details = {};
  let delivery_cost = gon.store.delivery_fee;
  // let cost_delivery = 0;
  let discount_amount = 0.0;
  let discount_amount_reward = 0.0;
  let discount_value = 0.0;
  let discount_type = 'dollar';
  let fee_amount = 0.0;
  const location_address = '';
  let itemIndex = 0;
  const cc_fee = 0.0;
  let debit_fee = 0.0;
  let weedmap_fee = 0.0;
  let is_bundle = false;
  let is_catalyst = false;
  const CC_PERCENT = gon.store.cc_percent;
  // const is_medical_order = $('#isMedical').prop('checked');

  $('#order_table').hide();
  $('#discount_div').hide();
  $('#additional_div').hide();
  $('#promoDetails').hide();
  $('#clear_discount').hide();
  $('#rewards_block').hide();

  const isHidden = true;

  const geocoder = new google.maps.Geocoder();
  let taxEl;
  let tax_value = 0.0;
  let tax_percent = 0.0;
  let county_seat = '';
  let tax_county = '';
  let tax_city = '';
  const currencyFormatter = new Intl.NumberFormat('en-us', {
    style: 'currency',
    currency: 'USD'
  });

  if ($('#order_app_user_id').val()) {
    loadCustomerInfo();
  }

  $('#county_seat_id').on('change', function() {
    tax_value = 0.0;
    tax_percent = 0.0;
    taxEl = $(this).find(':selected');
    tax_city = taxEl.attr('data-city');
    tax_county = taxEl.attr('data-county');

    $('#county_seat_tax_id').val(`%${taxEl.attr('data-tax-value')}`);
    calculateTotal();
  });

  $('#brand_search')
    .autocomplete({
      selectFirst: true,
      minLength: 0,
      scroll: true,
      classes: {
        'ui-autocomplete': 'highlight-autocomplite'
      },
      source: '/brand_search',
      select(e, ui) {
        $('#brandId').val(ui.item.value);
        $('#brand_search').val(ui.item.label);
        return false;
      }
    })
    .focus(function() {
      // reset result list's pageindex when focus on
      window.pageIndex = 0;
      const obj = $('#brand_search').val();
      $('#brand_search').val('');
      $(this).autocomplete('search', '');
      $('#brand_search').val(obj);
    });

  $('#brand_search').on('input', function() {
    if (!$('#brand_search').val()) {
      $('#brandId').val('');
    }
  });

  $('#search')
    .autocomplete({
      selectFirst: true,
      minLength: 0,
      scroll: true,
      classes: {
        'ui-autocomplete': 'highlight-autocomplite'
      },
      source(request, response) {
        const brandId = $('#brandId').val();
        $.getJSON(
          '/product_search',
          {
            term: request.term,
            loc_id: $('#store_location').val(),
            brand_id: brandId
          },
          response
        );
      },
      select(e, ui) {
        $('#submit_button').prop('disabled', 'disabled');
        $.get('/product_details', {
          product_id: ui.item.value,
          zip_code:  $('#zip_code').val(),
          location_id:  $('#store_location').val(),
          marketplace_id: $('#store_marketplace').val(),
          is_promo: false
        }).done(data => {
          $.get('/is_in_stock', {
            product_id: ui.item.value,
            change_location: false,
            promo: false,
            location_id: $('#store_location').val()
          })
            .done(avaliData => {
              if (avaliData.available === true) {
                addSinlgeItem(data, avaliData.qty);
              } else {
                alert(
                  `${ui.item.label} is out of stock at the ${$(
                    '#store_location option:selected'
                  ).text()} location.`
                );
              }
            })
            .done(() => {
              $('#search').val('');
              $('.quantity_list').on('change', function(e) {
                const quantity_value = this.value;
                const product_id = this.id;
                const quantity_text = $(
                  `#${product_id} option:selected`
                ).text();
                const item_to_change = order_items[product_id];
                updateLineItem(
                  quantity_text,
                  quantity_value,
                  item_to_change,
                  product_id
                );
              });
              $('.remove_button').click(function() {
                $('#submit_button').prop('disabled', 'disabled');
                const to_remove = this.id.substring(7);
                removeItem(to_remove);
              });
            });
        });
      }
    })
    .focus(function() {
      // reset result list's pageindex when focus on
      window.pageIndex = 0;
      const obj = $('#search').val();
      $('#search').val('');
      $(this).autocomplete('search', '');
      $('#search').val(obj);
    });
  $('#promo_product_search')
    .autocomplete({
      selectFirst: true,
      minLength: 0,
      scroll: true,
      classes: {
        'ui-autocomplete': 'highlight-autocomplite'
      },
      source(request, response) {
        const brandId = $('#brandId').val();
        $.getJSON(
          '/promo_product_search',
          {
            term: request.term,
            loc_id: $('#store_location').val(),
            brand_id: brandId
          },
          response
        );
      },
      select(e, ui) {
        $('#submit_button').prop('disabled', 'disabled');
        $.get('/product_details', {
          product_id: ui.item.value,
          zip_code:  $('#zip_code').val(),
          location_id:  $('#store_location').val(),
          marketplace_id: $('#store_marketplace').val(),
          is_promo: true
        }).done(data => {
          $.get('/is_in_stock', {
            product_id: ui.item.value,
            change_location: false,
            promo: true,
            location_id: $('#store_location').val()
          })
            .done(avaliData => {
              if (avaliData.available === true) {
                addSinlgeItem(data, avaliData.qty);
              } else {
                alert(
                  `${ui.item.label} is out of stock at the ${$(
                    '#store_location option:selected'
                  ).text()} location.`
                );
              }
            })
            .done(() => {
              $('#promo_product_search').val('');
              $('.quantity_list').on('change', function(e) {
                const quantity_value = this.value;
                const product_id = this.id;
                const quantity_text = $(
                  `#${product_id} option:selected`
                ).text();
                const item_to_change = order_items[product_id];
                updateLineItem(
                  quantity_text,
                  quantity_value,
                  item_to_change,
                  product_id
                );
              });
              $('.remove_button').click(function() {
                $('#submit_button').prop('disabled', 'disabled');
                const to_remove = this.id.substring(7);
                removeItem(to_remove);
              });
            });
        });
      }
    })
    .focus(function() {
      // reset result list's pageindex when focus on
      window.pageIndex = 0;
      const obj = $('#search').val();
      $('#search').val('');
      $(this).autocomplete('search', '');
      $('#search').val(obj);
    });

  $('#apply_discount_button').click(() => {
    $('#discount-value').val($('#discount').val());
    applayDiscount();
  });

  $('#discount').on('keyup', function() {
    if (!$('#promoId').val().length) {
      $('#apply_discount_button').prop('disabled', false);
    } else {
      $('#discount').val('');
      alert("You can't have double discounts,so remove promo discount!");
    }
  });

  $('#clear_discount_button').click(() => {
    discount_amount = 0.0;
    discount_value = 0.0;
    discount_type = 'dollar';
    $('#discount').val('');
    $('#promoId').val('');
    $('#promoName').html('');
    $('#promo_code_search').val('');
    $('#apply_discount_button').prop('disabled', true);
    $('#apply_promo_button').prop('disabled', true);
    calculateTotal();
  });

  $('#cc_fee_button').click(() => {
    if (subTotal == 0) {
      alert('Please enter order items before calculating Credit Card fee!');
      return;
    }

    /* cc_fee = (subTotal + delivery_cost + fee_amount - discount_amount) * CC_PERCENT;
    $("#cc_fee_label").text(`$${cc_fee.toFixed(2)}`); */
    calculateTotal();
  });

  $('input[type=radio][name=payment_type]').change(function() {
    switch ($(this).val()) {
      case 'Cash':
        $('#paymentMessage').css('display', 'none');
        debit_fee = 0.0;
        $('#debit_fee_label').text(currencyFormatter.format(debit_fee));
        $('#paytender_fee_label').text(currencyFormatter.format(debit_fee));
        $('#aeropay_fee_label').text(currencyFormatter.format(debit_fee));
        break;
      case 'Debit':
        $('#paymentMessage').css('display', 'none');
        debit_fee = parseFloat(gon.store.debit_card_fee, 10);
        break;
      case 'Paytender':
        $('#paymentMessage').css('display', 'none');
        debit_fee = parseFloat(gon.store.debit_card_fee, 10);
        break;
      case 'Aeropay':
        $('#paymentMessage').css('display', 'none');
        debit_fee = parseFloat(aeropay_fee_amount, 10);
        break;
      case 'Ledgergreen':
        $('#paymentMessage').css('display', 'block');
        break;
    }
    calculateTotal();
  });

  if ($('#remove_weedmap_fee_button').length > 0) {
    $('#remove_weedmap_fee_button').hide();
    $('#remove_weedmap_fee_button').click(function() {
      if (subTotal == 0) {
        alert('Please enter order items before calculating Weedmap fee!');
        return;
      }

      $(this).hide();
      $('#add_weedmap_fee_button').show();
      weedmap_fee = 0.0;
      calculateTotal();
    });
  }

  if ($('#add_weedmap_fee_button').length > 0) {
    $('#add_weedmap_fee_button').click(function() {
      if (subTotal == 0) {
        alert('Please enter order items before calculating Weedmap fee!');
        return;
      }
      $(this).hide();
      $('#remove_weedmap_fee_button').show();
      weedmap_fee = parseFloat(gon.store.weedmap_fee, 10);
      calculateTotal();
    });
  }


  if ($('#apply_delivery_fee_button').length > 0) {
    $('#apply_delivery_fee_button').hide();
    $('#apply_delivery_fee_button').click(function() {
      $(this).hide();
      delivery_cost = parseFloat(gon.store.delivery_fee, 10);
      $('#remove_delivery_fee_button').show();
      calculateTotal();
    });
  }

  if ($('#remove_delivery_fee_button').length > 0) {
    $('#remove_delivery_fee_button').click(function() {
      $(this).hide();
      delivery_cost = 0.0;
      $('#apply_delivery_fee_button').show();
      calculateTotal();
    });
  }

  if ($('#apply_taxes').length > 0) {
    $('#apply_taxes').click(() => {
      const extax = Number($('#excisetax').val());
      const saletax = Number($('#salestax').val());
      if (!isNaN(extax) && extax !== '' && extax > 0) {
        exciseTax = extax;
      } else {
        alert('Please enter a positive number for Excise Tax !');
        return;
      }

      if (!isNaN(saletax) && saletax !== '' && saletax > 0) {
        salesTax = saletax;
      } else {
        alert('Please enter a positive number for Sales Tax !');
        return;
      }
      calculateTotal();
    });
  }

  $('#add_charge_button').click(() => {
    const fee_value = parseFloat($('#additional_charge').val());
    if (!isNaN(fee_value) && fee_value !== '' && fee_value > 0) {
      fee_amount = fee_value;
      calculateTotal();
    } else {
      alert('Please enter a positive number for fee amount!');
    }
    $('#additional_charge').val('');
  });
  $('#remove_charge_button').click(() => {
    fee_amount = 0;
    calculateTotal();
  });

  $(document).on('click', '.loadCustomerInfo', function() {
    loadCustomerInfo();
  });

  function applayDiscount() {
    discount_value = Number($('#discount').val());
    discount_type = $("input[name='discount_type']:checked").val();
    promo_code_id = Number($('#promoId').val());
    const product_count = Object.keys(order_items).length;
    if (product_count >= 1 && promo_code_id == 0) {
      if (
        !isNaN(discount_value) &&
        discount_value !== '' &&
        parseFloat(discount_value, 10) > 0.0
      ) {
        if (discount_type == 'percentage') {
          subTotal  = parseFloat(
            _.reduce(
              order_items,
              (acc, item) => {
                acc += item.cost_info.cost * item.cost_info.quantity;
                return acc;
              },
              0
            ).toFixed(2)
          );
          discount_amount = ((subTotal - excludedSubTotal) * discount_value) / 100;
        } else {
          discount_amount = discount_value;
        }
        if (has_tax === false) {
          if (discount_amount === ((subTotal - excludedSubTotal) + delivery_cost)) {
            calculateTotal();
          } else if (discount_amount > ((subTotal - excludedSubTotal) + delivery_cost)) {
            alert('Discount amount can not be more then sub total amount');
          } else {
            calculateTotal();
          }
        } else {
          if (discount_amount > (subTotal - excludedSubTotal)) {
            alert('Discount amount can not be more then sub total amount');
          } else if (parseFloat(discount_value, 10) < 0.0) {
            alert('Please enter a positive number for discount amount!');
          } else {
            calculateTotal();
          }
        }
      } else {
        alert('Please enter a positive number for discount amount!');
        $('#discount').val('');
      }
    }
  }

  /**
   * load/update customer data
   */
  function loadCustomerInfo() {
    $.get('/customer_display_info', {
      customer_id: $('#order_app_user_id').val()
    }).done(data => {
      populateCustomerInfo(data);
      calculateTotal();
      getPoints(data)
      $('#customerInfoLoader').hide();
      $('#customerInfoDiv').show();
    });
  }

  /** autocomplete with mapBox * */
  function autocompleteMapBox(type = false, edit_costumer = false) {
    mapboxgl.accessToken = gon.map_box_access_token;
    if (type) {
      if (edit_costumer) {
        var map_container = `map_${type}_edit`;
        var geocoder_id = `geocoder_${type}_edit`;
        var address = 'address_edit';
        var zip = 'zip_edit';
        var city = 'city_edit';
        var state = 'state_edit';
        var lat = 'lat_edit';
        var lng = 'lng_edit';
      } else {
        var map_container = `map_${type}`;
        var geocoder_id = `geocoder_${type}`;
        var address = 'address';
        var zip = 'zip';
        var city = 'city';
        var state = 'state';
        var lat = `${type}LatId`;
        var lng = `${type}LngId`;
      }
    } else {
      var map_container = 'map_box';
      var geocoder_id = 'geocoder';
      var lat = 'latId';
      var state = 'order_region';
      var lng = 'lngId';
      var zip = 'zip_code';
      var address = 'order_delivery_address';
    }
    $(`#${geocoder_id}`).html('');
    const map = new mapboxgl.Map({
      container: map_container,
      style: 'mapbox://styles/mapbox/streets-v9',
      center: [-118.6919161, 34.0207305],
      zoom: 13
    });

    const geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      placeholder: 'Address',
      countries: 'us',
      filter: function(item) {
        return item.context
          .map(function(i) {
            return (
              i.id.split('.').shift() === 'region' && (i.text === 'California' || i.text === 'New York')
            );
          })
          .reduce(function(acc, cur) {
            return acc || cur;
          });
      },
      name: 'deliver_address',
      mapboxgl: mapboxgl
    });

    geocoder.on('result', function(e) {
      const { coordinates } = e.result.geometry;

      $(`#${lng}`).val(coordinates[0]);
      $(`#${lat}`).val(coordinates[1]);

      $(`#${address}`).val(e.result.place_name);

      const { context } = e.result;
      document.getElementById(zip).value = '';
      if (type) {
        document.getElementById(city).value = '';
        document.getElementById(state).value = '';
      }

      $.each(context, function(index, value) {
        const context_type = value.id.split('.')[0];
        switch (context_type) {
          case 'place':
            if (type) {
              document.getElementById(city).value = value.text;
            }
            break;
          case 'region':
            if (type) {
              const short_code = value.short_code.split('-')[1];
              document.getElementById(state).value = short_code;
            }
            break;
          case 'postcode':
            document.getElementById(zip).value = value.text;
            break;
        }
      });
    });
    $(`#${geocoder_id}`).append(geocoder.onAdd(map));
    $('.mapboxgl-ctrl-geocoder--input').attr('autocomplete', 'off');
  }

  /** Get tax by region and state using delivery address * */
  function loadCoords(address) {
    geocoder.geocode({ address: address }, (results, status) => {
      if (status === 'OK') {
        const filtered_county = results[0].address_components.filter(
          address_component =>
            address_component.types.includes('administrative_area_level_2')
        );
        const filtered_city = results[0].address_components.filter(
          address_component => address_component.types.includes('locality')
        );

        let county = filtered_county.length ? filtered_county[0].long_name : '';
        const city = filtered_city.length ? filtered_city[0].long_name : '';

        const lastIndex = county.lastIndexOf(' ');
        county = county.substring(0, lastIndex);
        county_seat = `${city}-${county}`;
        tax_city = city;
        tax_county = county;

        $('#county_seat_id option')
          .first()
          .prop('selected', true);

        $('#county_seat_id option').each(function() {
          if ($(this).attr('data-attr') == county_seat) {
            $(this).prop('selected', true);
          }
        });
        if (Object.keys(order_items).length) {
          calculateTotal();
        }
      }
    });
  }

  function getTaxOfState() {
    $.get(
      '/taxes/get_tax_by_address',
      {
        tax_county,
        tax_city
      },
      data => {
        if (data.tax && data.tax.value) {
          tax_percent = data.tax.value;
        }
      }
    );
  }

  $('#medical_order').change(function() {
    if (this.checked) {
      $('#medical').prop('checked', true);
      $('#medical_id').css('display', 'block');
    } else {
      $('#medical_id').css('display', 'none');
    }
  });

  $('#expiration_date').datepicker();

  $('#medical_order').on('click', function() {
    calculateTotal();
  });
  $('#medical').on('click', function() {
    calculateTotal();
  });
  $('#state_medical').on('click', function() {
    calculateTotal();
  });
  $('#reward').on('change', function(){
    let reward_discount_amount = $('#reward').val()
    if(reward_discount_amount > (subTotal + delivery_cost)){
      alert('Discount amount can not be more then sub total amount');
    }else{
      calculateTotal();
    }
  })

  function calculateTotal(update_values = true) {

    let map_box_access_token = gon.map_box_access_token;
    let zip_code_val = $('#zip_code').val();
    let promo_code_id = $('#promoId').val();
    let error_count = 0;
    let error_message = '';

    if (!(zip_code_val === '' || zip_code_val === null) && $("#table_body tr").length) {
      if (zip_code_val.length != 5) {
        error_count++;
        error_message += '<li> Zip code must be five digits. </li>';
        $('#submit_button').attr('disabled', 'disabled');
      }else{
        $('.errors').hide();
        $('#order_errors').hide();
        $.get({
            url: `https://api.mapbox.com/geocoding/v5/mapbox.places/${zip_code_val}.json?access_token=${map_box_access_token}`,
            async:false
             })
             .done(data => {
               var wrongZipCode = false;
               for (var i = 0; i < data.features.length; i++) {
                 if (data.features[i].place_name.includes('California') || data.features[i].place_name.includes('New York')) {
                   wrongZipCode = false;
                   break;
                 }else{
                   wrongZipCode = true;
                 }
               }
               if(wrongZipCode){
                 error_count++;
                 error_message += '<li> Please enter the correct zip code. </li>';
                 $('#submit_button').attr('disabled', 'disabled');
               }
             });
      }

              if (error_count > 0) {
                $('#order_errorsUl').html(error_message);
                $('.errors').html(error_message);
                $('.errors').show();
                $('#order_errors').show();
                return false;
               }
                $('#submit_button').prop("disabled", false);

    }

      getTaxOfState();
      var total_fee = delivery_cost + recreationalFee;
      var is_medical_order = $('#medical_order').prop('checked');
      var medical_patient = '';
      if (is_medical_order) {
        medical_patient = $('input[name=medical_patient]:checked').val();
      }
      $('#submit_button').prop("disabled", true);

    if (Object.keys(order_items).length){

      var productDetails = _.filter(order_items, item => item.tax_excluded !== true).map(item => ({
        id: item.id,
        quantity: item.cost_info.quantity,
        cost: item.cost_info.cost,
        cannabis: item.cost_info.isCannabis,
        product_discount_price: item.product_discount_price,
        product_discount_quantity: item.product_discount_quantity,
        is_tax_excluded: item.tax_excluded
      })).flat();
      if (productDetails.length == 0){
        total_fee = 0.0
      }
        $.get('/orders/costs/delivery',
          {
            product_details: productDetails,
            order_items: JSON.stringify(productDetails),
            zip_code: $('#zip_code').val(),
            city_name: $('#city_name').val(),
            remove_limit: $('#remove_limit').is(':checked'),
            location_id: Number($('#store_location').val()),
            marketplace_id: Number( $('#store_marketplace').val()),
            customer_id,
            promo_code_id,
            discount_amount: parseFloat(discount_amount),
            discount_amount_reward: $('#reward').val(),
            reward_point: $('#reward').find(':selected').data('point'),
            discount_type: discount_type,
            discount_value: parseFloat(discount_value),
            total_fee_value: total_fee,
            medical_order: is_medical_order,
            medical_patient: medical_patient,
            in_store_pickup: $('#in_store_pickup').prop('checked')
          },
          data => {
            const costs = _.mapValues(data, item => {
              if (typeof item === 'number') item = parseFloat(item.toFixed(2));
              return item;
            });

            if(data.order_items){
              console.log('update')
              updateCost(order_items, data.order_items)
              updateCost(productDetails, data.order_items, true)
            }

            const itemsExcluded = _.filter(order_items, item => item.tax_excluded == true).map(item => ({
              id: item.id,
              quantity: item.cost_info.quantity,
              cost: item.cost_info.cost,
              cannabis: item.cost_info.isCannabis,
              product_discount_price: item.product_discount_price,
              product_discount_quantity: item.product_discount_quantity
            })).flat();
            if (itemsExcluded.length > 0) {
              const pickupIsChecked = $('#in_store_pickup').is(':checked');
              if (itemsExcluded.length > 0 && productDetails.length == 0) {
                delivery_cost = 0.0
              } else {
                delivery_cost = pickupIsChecked ? 0 : $('#remove_delivery_fee_button').is(':hidden') ? 0.0 : parseFloat(gon.store.delivery_fee, 10);;
                pickupIsChecked ? $('#remove_delivery_fee').hide() : $('#remove_delivery_fee').show();
              }
            excludedSubTotal = parseFloat(
              _.reduce(
                itemsExcluded,
                (acc, item) => {
                  acc += item.cost * item.quantity;
                  return acc;
                },
                0
              ).toFixed(2)
            );
            productDetails= [...productDetails, ...itemsExcluded];
          }else{
            excludedSubTotal = 0;
          }
		      const taxes = costs.line_items;
          recreationalFee = costs.recreational_fee_anount;
          city_tax_amount = costs.city_tax_amount || 0;
		      business_city_tax = costs.business_city_tax || 0;
          cityTaxId = costs.city_tax_id;
          has_tax = costs.has_tax;
          discount_amount = costs.discount_amount
          discount_amount_reward = costs.discount_amount_reward

		  if (!gon.store.dutchie_tax) {
            if (!gon.store.remove_excise_tax) {
              exciseTax = costs.excise_tax;
            }
            salesTax = costs.salesTax;
          }
          is_catalyst = costs.is_catalyst;
            let location_is_shipping = $('#store_location')
              .find(':selected')
              .data('is-shipping');
            console.log('location_is_shipping', location_is_shipping);
      const minimum_price = $('#cost_shipping').find(':selected').data('minimum-price');
      if (costs.is_catalyst) {
            subTotal = parseFloat(
              _.reduce(
                taxes,
                (acc, item) => {
                  acc += item.outdoorPrice;
                  return acc;
                },
                0
              ).toFixed(2)
            );
            if (location_is_shipping && (parseFloat(minimum_price) <= parseFloat(subTotal))){
              delivery_cost  = 0
            }
            totalCost = (
              subTotal +
              excludedSubTotal +
              parseFloat(delivery_cost) +
              parseFloat(cc_fee) +
              parseFloat(fee_amount) +
              parseFloat(debit_fee) +
              parseFloat(weedmap_fee) +
              recreationalFee
            ).toFixed(2);
      } else {
            subTotal = parseFloat(
              _.reduce(
                productDetails,
                (acc, item) => {
                  acc += item.cost * item.quantity;
                  return acc;
                },
                0
              ).toFixed(2)
            );
        if (location_is_shipping && (parseFloat(minimum_price) <= parseFloat(subTotal))){
              delivery_cost  = 0
            }
            totalCost = (
              subTotal +
              parseFloat(delivery_cost) +
              parseFloat(cc_fee) +
              parseFloat(fee_amount) +
              parseFloat(debit_fee) +
              parseFloat(city_tax_amount) +
              parseFloat(weedmap_fee) +
              recreationalFee +
              exciseTax +
              salesTax -
              parseFloat(discount_amount) -
              parseFloat(discount_amount_reward)
            ).toFixed(2);
          }

          if(update_values){
            $('#subtotal_label').text(currencyFormatter.format(subTotal));
            $('#delivery_cost_label').text(currencyFormatter.format(delivery_cost));
            $('#total_label').text(currencyFormatter.format(parseFloat(totalCost)));
            // $("#cc_fee_label").text(currencyFormatter.format(cc_fee));
            $('#discount_cost_label').text(
              `- ${currencyFormatter.format(discount_amount)}`
            );
            $('#reward_discount_cost_label').text(
              `- ${currencyFormatter.format(discount_amount_reward)}`
            );
            $('#additional_label').text(
              currencyFormatter.format(parseFloat(fee_amount))
            );
            $('#recreational_fee_label').text(
              currencyFormatter.format(recreationalFee)
            );
            $('#excise_tax_label').text(currencyFormatter.format(exciseTax));
            $('#sales_tax_label').text(currencyFormatter.format(salesTax));

            if ($('#debit_fee_label').length && $('input[name=payment_type]:checked').val() == 'Debit') {
              $('#debit_fee_label').text(currencyFormatter.format(debit_fee));
              $('#paytender_fee_label').text(`$0.00`);
              $('#aeropay_fee_label').text(`$0.00`);
            }

            if ($('#paytender_fee_label').length && $('input[name=payment_type]:checked').val() == 'Paytender') {
              $('#paytender_fee_label').text(currencyFormatter.format(debit_fee));
              $('#debit_fee_label').text(`$0.00`);
              $('#aeropay_fee_label').text(`$0.00`);
            }

            if ($('#aeropay_fee_label').length && $('input[name=payment_type]:checked').val() == 'Aeropay') {
              $('#aeropay_fee_label').text(currencyFormatter.format(debit_fee));
              $('#debit_fee_label').text(`$0.00`);
              $('#paytender_fee_label').text(`$0.00`);
            }

            if ($('#weedmap_fee_label').length) {
              $('#weedmap_fee_label').text(currencyFormatter.format(weedmap_fee));
            }
            $('#city_tax_id').html(
              `${currencyFormatter.format(  city_tax_amount)} (%${parseFloat(costs.city_tax)})`
            );
          $('#reward-loyalty-checkout-data').attr('cart-subtotal-cents', parseFloat(parseFloat(totalCost) * 100 ));
          $('#reward-loyalty-cart-data').attr('data-free-product-points', parseFloat(parseFloat(discount_amount_reward) * 10 ));
          $('#reward-loyalty-cart-data').attr('data-applied-coupon-points', parseFloat(parseFloat(discount_amount_reward) * 10 ));
          $('.errors').hide();
          $('#order_errors').hide();
          checkRequiredFields();
          addTaxesToOrderItems(order_items, taxes)
        }
        }
      ).fail(data => {
        let error_count = 0;
        let error_message = '';
        const error = get(data, 'responseJSON.error.message', null);
        if (error) {
          error_count++;
          error_message += `<li>${error}</li>`;
        } else {
          if ($('#zip_code').val() === '' || $('#zip_code').val() === null) {
            error_count++;
            error_message += '<li>Zip Code is required.</li>';
          }
          if (
            $('#store_location').val() === '' ||
            $('#store_location').val() === null
          ) {
            error_count++;
            error_message += '<li>Store Location is required.</li>';
          }
        }

        if (error_count > 0) {
          $('#order_errorMsg').html(
            `There are ${error_count} error(s) in your submission.`
          );
          $('#order_errorsUl').html(error_message);
          $('.errors').html(error_message);
          $('.errors').show();
          $('#order_errors').show();
        } else {
          checkRequiredFields();
        }
      });
   }else {
    removeProduct();
   }
  }

  function updateLineItem(quantity_text, quantity_value, product, lineItemId) {
    console.log('===============================is_catalyst', is_catalyst)
    if (quantity_text === '1 GRAM') {
      $(`#cost_${lineItemId}`).html(`$${product.gram_price.toFixed(2)}`);
      var item = order_items[lineItemId];
      item.cost_info.quantity = '1 GRAM';
      item.cost_info.cost = product.gram_price;
    } else if (quantity_text === '2 GRAMS') {
      $(`#cost_${lineItemId}`).html(`$${(product.gram_price * 2).toFixed(2)}`);
      var item = order_items[lineItemId];
      item.cost_info.quantity = '2 GRAMS';
      item.cost_info.cost = product.gram_price * 2;
    } else if (quantity_text === '1/8') {
      $(`#cost_${lineItemId}`).html(`$${product.eigth_price.toFixed(2)}`);
      var item = order_items[lineItemId];
      item.cost_info.quantity = '1/8';
      item.cost_info.cost = product.eigth_price;
    } else if (quantity_text === '1/4') {
      $(`#cost_${lineItemId}`).html(`$${product.quarter_price.toFixed(2)}`);
      var item = order_items[lineItemId];
      item.cost_info.quantity = '1/4';
      item.cost_info.cost = product.quarter_price;
    } else if (quantity_text === '1/2') {
      $(`#cost_${lineItemId}`).html(`$${product.half_price.toFixed(2)}`);
      var item = order_items[lineItemId];
      item.cost_info.quantity = '1/2';
      item.cost_info.cost = product.half_price;
    } else if (quantity_text === 'OZ') {
      $(`#cost_${lineItemId}`).html(`$${product.oz_price.toFixed(2)}`);
      var item = order_items[lineItemId];
      item.cost_info.quantity = 'OZ';
      item.cost_info.cost = product.oz_price;
    } else {
      if(is_catalyst){
        $(`#cost_${lineItemId}`).html(
          `$${( order_items[lineItemId].outdoor_price * quantity_value).toFixed(2)}`
        );
      }else{
        $(`#cost_${lineItemId}`).html(
          `$${( product.unit_price * quantity_value).toFixed(2)}`
        );
      }

      //
      var item = order_items[lineItemId];
      item.cost_info.quantity = parseFloat(quantity_value);
      item.cost_info.cost = parseFloat(product.unit_price);
      item.cost_info.total_cost = parseFloat(
        product.unit_price * quantity_value
      );
    }
    let isUpdateComponents =
      discount_value == null ||
      typeof discount_value === 'undefined' ||
      discount_value == 0.0;
    calculateTotal(isUpdateComponents);

    if (!isUpdateComponents) {
      setTimeout(applayDiscount, 1200);
    }
  }

  function removeItem(item_id) {
    // Remove from table
    $(`#row_${item_id}`).remove();
    // Remove from hash
    delete order_items[item_id];
    const product_count = Object.keys(order_items).length;
    if (product_count < 1) {
      discount_amount = 0.0;
      discount_value = 0.0;
      discount_type = 'dollar';
      $('#discount').val('');
    }
    let isUpdateComponents =
      discount_value == null ||
      typeof discount_value === 'undefined' ||
      discount_value == 0.0;
    calculateTotal(isUpdateComponents);

    if (!isUpdateComponents) {
      setTimeout(applayDiscount, 1300);
    }
    hideBlocks();
  }

  function addSinlgeItem(data, avail_qty) {
    const single_price_tag = getSinglePriceQtyTag(data, itemIndex, avail_qty);
    // Check if item already in cart before adding
    let exists_already = false;
    Object.keys(order_items).map(item => {
      if (order_items[item].id === data.id) {
        alert('That item has already been added!');
        exists_already = true;
      }
    });
    if (exists_already === true) {
      $('#submit_button').prop('disabled', false);
      return;
    }
    if (data.unit_price === null) {
      data.unit_price = 0.0;
    }
    if (data.outdoor_price === 0 && !gon.is_catalyst) {
      data.outdoor_price = data.unit_price;
    }
    const bundle = data.is_bundle ? '| bundle |' : '';

    $('#table_body').append(
      `<tr data-bundle="${data.is_bundle}" id="row_${itemIndex}"><td>${data.brand_name} | ${data.name} ${bundle} (${avail_qty} in stock)` +
        `</td><td>${single_price_tag}</td><td>$${data.original_price}</td><td id="cost_${itemIndex}">$${data.outdoor_price.toFixed(2)}</td>` +
        `<td><a id="remove_${itemIndex}" class="remove_button">Remove</a></td></tr>`
    );
    hideBlocks();
    const new_order_item = data;

    new_order_item.cost_info = {
      quantity: 1,
      cost: data.unit_price,
      outdoor_price: data.outdoor_price,
      isCannabis: data.is_canabis
    };
    // order_items[data.id] = new_order_item;
    order_items[itemIndex] = new_order_item;

    itemIndex++;
    let isUpdateComponents =
      discount_value == null ||
      typeof discount_value === 'undefined' ||
      discount_value == 0.0;
    calculateTotal(isUpdateComponents);

    if (!isUpdateComponents && !data.is_bundle) {
      setTimeout(applayDiscount, 1200);
    }
  }
  function updateItemUnitPrice(data) {
    // Check if item already in cart before adding
    const exists_already = false;
    Object.keys(order_items).map(item => {
      if (order_items[item].id === data.id) {
        $(`#cost_${  item}`).html(data.unit_price.toFixed(2))
        order_items[item].cost = data.unit_price;
        order_items[item].cost_info.cost = data.unit_price;
        calculateTotal(true);
      }
    });
  }

  function hideBlocks() {
    is_bundle = false;
    $('#table_body tr').each(function() {
      if ($(this).attr('data-bundle') == 'true') {
        is_bundle = true;
      }
    });

    if (isHidden) {
      $('#order_table').show();
      if (is_bundle || !$('#table_body tr').length) {
        $('#discount_div').hide();
        $('#promoDetails').hide();
        $('#clear_discount').hide();
        $('#additional_div').hide();
        $('#rewards_block').hide();
        $('#weedmap_fee').hide();
        $('#clear_discount_button').trigger('click');
      } else {
        $('#discount_div').show();
        $('#promoDetails').show();
        $('#clear_discount').show();
        $('#additional_div').show();
        if(enable_reward){
          $('#rewards_block').show();
        }
        $('#weedmap_fee').show();
      }
    }
  }

  function getMinAmount(data) {
    if (data.gram_price !== null) {
      return data.gram_price;
    }
    if (data.eigth_price !== null) {
      return data.eigth_price;
    }
    if (data.quarter_price !== null) {
      return data.quarter_price;
    }
    if (data.half_price !== null) {
      return data.half_price;
    }
    if (data.oz_price !== null) {
      return data.oz_price;
    }
    return 0;
  }

  function getSinglePriceQtyTag(data, theIndex, max) {
    if (max > 50) {
      max = 50;
    }
    let single_price_tag = `<select id="${theIndex}" class="quantity_list">`;
    for (let i = 1; i <= max; i++) {
      single_price_tag += `<option>${i}</option>`;
    }
    single_price_tag += '</select>';

    return single_price_tag;
  }

  function getMultiPriceQtyTag(product, theIndex) {
    let multi_price_tag = `<select id="${theIndex}" class="quantity_list">`;

    if (product.gram_price !== null) {
      multi_price_tag += '<option value="1">1 GRAM</option>';
      multi_price_tag += '<option value="2">2 GRAMS</option>';
    }

    if (product.eigth_price !== null) {
      multi_price_tag += '<option value="3.5">1/8</option>';
    }

    if (product.quarter_price !== null) {
      multi_price_tag += '<option value="7">1/4</option>';
    }

    if (product.half_price !== null) {
      multi_price_tag += '<option value="14">1/2</option>';
    }

    if (product.oz_price !== null) {
      multi_price_tag += '<option value="28">OZ</option>';
    }

    multi_price_tag += '</select>';

    return multi_price_tag;
  }

  $('#errors').hide();
  $('#errors_edit').hide();
  $('#order_errors').hide();
  $('#new_order').submit(() => false);

  $('#is_curbside').click(() => {
    const curbsideIsChecked = $('#is_curbside').is(':checked');
    if (curbsideIsChecked == true) {
      $('.cash-curbside').hide();
      $('.curbside').show();
      $('#cash').attr('checked', false);
    } else {
      $('.cash-curbside').show();
      $('.curbside').hide();
    }
  });

  $('#in_store_pickup').click(() => {
    const pickupIsChecked = $('#in_store_pickup').is(':checked');
    const curbsideIsChecked = $('#is_curbside').is(':checked');
    if (pickupIsChecked === true) {
      $('#react-hot-toast').attr('disabled', 'disabled');
      $('#orderOriginId').hide();
      $('.curbside-checkbox').show();
      if(curbsideIsChecked == true){
        $('.cash-curbside').hide();
        $('.curbside').show();
      }
      $('#delvieryAddressId').hide();
      $('#apartmentNumberId').hide();
      $('#customer_address_button').hide();
      $('#delivery_cost_label').text(currencyFormatter.format(0));
      $('#total_label').text(currencyFormatter.format(0));

      let loc_id = $('#store_location').val();
      if (loc_id !== null && loc_id != 0 && loc_id != '') {
        $.get('/delivery_calc', {
          location_id: $('#store_location').val()
        }).done(data => {
          $('#zip_code').val(data.zip_code);
        });
      }
      delivery_cost = 0;
      calculateTotal();
      $('#remove_delivery_fee').hide();
    } else {
      $('#order_delivery_address').removeAttr('disabled');
      $('#customer_address_button').show();
      $('#orderOriginId').show();
      $('.curbside-checkbox').hide();
      $('.curbside').hide();
      $('#is_curbside').prop('checked', false);
      $('.cash-curbside').css('display', 'initial');
      $('#delvieryAddressId').show();
      $('#apartmentNumberId').show();
      $('#remove_delivery_fee').show();
      $('#delivery_cost_label').text(currencyFormatter.format(delivery_cost));
      $('#total_label').text(currencyFormatter.format(delivery_cost));
      $('#zip_code').val('');
      delivery_cost = gon.store.delivery_fee;
      calculateTotal();
    }
  });

  $('#submit_button').click(() => {
    $('#submit_button').attr('disabled', 'disabled');
    if (!$('input[name=payment_type]:checked').val()) {
      alert('Payment type is required');
      return false;
    }
    const inStorePickup = $('#in_store_pickup').is(':checked');
    let discount_type_value = $('input[name=discount_type]:checked').val();
    let discount_percent_value = 0.0;
    let promo_code_id = $('#promoId').val();
    if (discount_type_value === 'percentage') {
      discount_percent_value = discount_value;
    }
    const productDetails = _.map(order_items, item => ({
      id: item.id,
      quantity: item.cost_info.quantity,
      cost: item.cost_info.cost,
      sales_tax: item.cost_info.sales_tax,
      city_tax: item.cost_info.city_tax,
      excise_tax: item.cost_info.excise_tax,
      cannabis: item.cost_info.isCannabis,
      product_discount_price: item.product_discount_price,
      product_discount_quantity: item.product_discount_quantity,
      is_tax_excluded: item.tax_excluded
    })).flat();

    const payload = _.omitBy(
      {
        order_items: JSON.stringify(Object.values(order_items)),
        productDetails: JSON.stringify(productDetails),
        user_id: $('#order_app_user_id').val(),
        delivery_address: $('#order_delivery_address').val(),
        apartment_number: $('#apartment_number').val(),
        lat: $('#latId').val(),
        lng: $('#lngId').val(),
        zip_code: $('#zip_code').val(),
        full_name: $('#full_name').val(),
        phone_number: $('#phone_number').val(),
        cost: $('#cost_shipping').find(':selected').data('cost'),
        cost_name:$('#cost_shipping').find(':selected').data('cost-name'),
        total_cost: totalCost,
        subtotal: subTotal,
        discount_amount,
        discount_amount_reward,
        discount_point_reward:$('#reward').find(':selected').data('point'),
        delivery_cost,
        promo_code_id: promo_code_id,
        additional_charge: fee_amount,
        excise_tax: exciseTax,
        sales_tax: salesTax,
        city_tax: city_tax_amount,
        business_city_tax: business_city_tax,
        city_tax_id: cityTaxId,
        cc_fee,
        debit_fee,
        weedmap_fee,
        location_address,
        in_store_pickup: inStorePickup,
        comment: $('#comments').val(),
        origin: $('#order_origin').val(),
        location_id: $('#store_location').val(),
        marketplace_id: $('#store_marketplace').val(),
        recreational_fee: recreationalFee,
        payment_type: $('input[name=payment_type]:checked').val(),
        zone_id: $('#zone').val(),
        requested_delivery_time: $('#requested_delivery_time').val(),
        discount_type: discount_type_value,
        discount_percent: discount_percent_value,
        remove_limit: $('#remove_limit').is(':checked'),
        medical_order: $('#medical_order').is(':checked'),
        which_aeropay: which_aeropay,
        is_curbside: inStorePickup ? $('#is_curbside').is(':checked') : false,
        make: $('#make').val(),
        model: $('#model').val(),
        color: $('#color').val(),
        curbside_time: $('#curbside_time').val()
      },
      item => _.isNil(item) || item === ''
    );

    let is_medical_patient = $('#medical_order').is(':checked');
    let check_med = false;

    if (is_medical_patient) {
      const medical = _.omitBy(
        {
          expiration_date: $('#expiration_date').val(),
          user_id: $('#order_app_user_id').val(),
          medical_number_id: $('#medical_number_id').val()
        },
        item => _.isNil(item) || item === ''
      );

      $.post({ url: '/medical_id_number', data: medical, async: false })
        .done(data => {
          if (_.isUndefined(data)) {
            return;
          }
          if (!data.success) {
            displayErrors(data.error);
            check_med = true;
          }
        })
        .fail(data => {
          if (get(data, 'responseJSON.error.message')) {
            const errorMessage = get(data, 'responseJSON.error.message');
            const errorElement = $('<li></li>').text(errorMessage);
            displayErrors(errorElement);
          }
        });
      if (check_med) {
        return false;
      }
    }

    $.post('/create_phone_order', payload)
      .done(data => {
        if (!data.success) {
          displayErrors(data.error);
        } else if (data.retail == true){
            window.location.replace(`/retail_orders`);
          }else{
            window.location.replace(`/orders/${data.order_id}#klavio`);
          }
      })
      .fail(data => {
        if (get(data, 'responseJSON.error.message')) {
          const errorMessage = get(data, 'responseJSON.error.message');
          const errorElement = $('<li></li>').text(errorMessage);
          console.log('errorElement: ', errorElement);
          displayErrors(errorElement);
        }
      });
  });

  function displayErrors(errors) {
    $('#order_errorMsg').html('There are error(s) in your submission.');
    $('#order_errorsUl').html(errors);
    $('#order_errors').show();
    $('#submit_button').removeAttr('disabled');
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }

  $('#promo_code_search')
    .autocomplete({
      selectFirst: true,
      minLength: 0,
      scroll: true,
      classes: {
        'ui-autocomplete': 'highlight-autocomplite'
      },
      source(request, response) {
        const brandId = $('#brandId').val();
        $.getJSON(
          '/promo_code_search',
          {
            term: request.term,
            marketplace_id: $('#store_marketplace').val(),
          },
          response
        );
      },
      select(e, ui) {
        $('#promoId').val(ui.item.value);
        $('#apply_promo_button').prop('disabled', false);
        $('#promo_code_search').val('');
        $('#promoName').html(ui.item.label);
        return false;
      }
    })
    .focus(function() {
      // reset result list's pageindex when focus on
      window.pageIndex = 0;
      const obj = $('#promo_code_search').val();
      $('#promo_code_search').val('');
      $(this).autocomplete('search', '');
      $('#promo_code_search').val(obj);
      discount_value = 0.0;
      $('#apply_discount_button').prop('disabled', true);
      $('#discount').val('');
    });

  $('#promo_code_search').on('input', function() {
    if (!$('#promo_code_search').val()) {
      $('#promoId').val('');
      $('#discount').val('');
    }
  });

  $('#apply_promo_button').click(() => {
    if ($('#order_app_user_id').val()) {
      calculateTotal();
    } else {
      alert('Please select a customer first!');
    }
  });
});
